$pageWidth:1000px;
$colorBackground:#191919;
$colorBlack:#0f0f0f;
$colorGrey:#333333;
$colorGreyLight:#666666;
$colorGreyDark:#999999;
$colorWhite:#ffffff;
$colorTheme:#FFCC00;

$fontTheme: 'BigHeadFont';
$fontDefault: 'Helvetica';

@font-face {
    font-family: $fontTheme; /* You can choose any name you prefer */
    src: url('../fonts/ed-interlock.otf') format('opentype');
}

html,body{
    margin: 0px;
    padding: 0px;
    font-family: $fontDefault;
    background-color: $colorBlack;
    background-image: url('../assets/background-body.jpg');
    background-size: 200px;
}

[data-case='upper']{
    text-transform: uppercase;
}

h1,h2,h3,h4{
    font-family: BigHeadFont;
    font-weight: normal;
}

header{
    margin: 0px;
    padding: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    background-color: rgba( $colorWhite, 0.75 );
    
    display: flex;
    flex-direction: row;
    align-items: center;

    h1{
        margin: 0px 10px;
    }

    nav{
        margin: 0px;
        padding: 0px;
        overflow: hidden;
        flex-grow: 1;


        ul{
            list-style: none;
            margin: 0px;
            padding: 0px;

            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;

            li{
                margin: 5px 5px 5px 0px;
                padding: 8px 10px;
                background-color: $colorBlack;
                color: $colorWhite;
                
                a{
                    color: $colorWhite;
                    text-decoration: none;

                    &:hover{
                        text-decoration: underline;
                    }
                }

                border-radius: 5px;
            }
        }
    }
}

.hero{
    height: 50vh;
    background-image: url('../assets/background-home.jpg');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    padding-top: 50px;
    box-sizing: border-box;
    color: rgba($colorWhite, 0.95);

    h1{
        font-size: 4rem;
        text-shadow: 0px 0px 20px rgba( $colorBlack, 1);
    }

    &.home{
        background-image: url('../assets/background-home.jpg');
    }
    
    &.drop-beat{
        background-image: url('../assets/background-drop-beat.jpg');
    }
}

main{
    padding: 20px 30px;
    color: white;

    max-width: 600px;
    margin: 0px auto;

    a{
        color: $colorWhite;

        &.cta{
            display: inline-block;
            margin: 5px 0px;
            padding: 15px;
            background-color: $colorWhite;
            color: $colorBlack;
            border-radius: 5px;
        }
    }

    p{
        margin: 10px 0px 30px 0px;
    }

    video{
        width: 100%;
    }

}


footer{
    background-color: $colorWhite;
    padding: 3px 20px;
    margin: 100px 0px 0px 0px;
}